import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import React, { Fragment } from "react";
const Layout = () => {
  return (
    <Fragment>
      <Header />
       <div className="container p-0">
       <Outlet />
       </div> 
      <Footer />
    </Fragment>
  );
};

export { Layout };
