import React from "react";
import Page from "../ui/Page";


const Default=()=>{

  return (
    <Page title="Welcome to Ideal Training Academy">
    
   dashboard
      
    </Page>
  );
}

export {Default};