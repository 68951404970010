import React, { Component } from "react";
export default class Footer extends Component {
  render() {
    return (
      <div className="container  border-1 border p-0  d-print-none ">
        <div className="card p-2 pt-4 border-0 bg-primary text-white">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className=" pull-left">
                <div className="d-flex align-items-center">
                  <h4 className="font-weight-bold   p-1 rounded-1  fw-bold">
                    IDEAL{" "}
                  </h4>
                  <h4 className="">TRAINING  SCIENCE ACADEMY </h4>
                </div>
                <p className=" p-2">
                  The Academy started its journey in 2021. The management of The
                  Academy, in keeping with their commitment to imparting quality
                  education, wanted to bring this excellence to the master
                  levels.
                </p>
                <ul className="list-inline  ps-2 font-weight-bold   fw-bold">
                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-facebook  fa-2x   text-white" />{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-twitter fa-2x text-white" />{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-youtube fa-2x text-white" />{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-google fa-2x text-white" />{" "}
                    </a>{" "}
                  </li>

                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-linkedin fa-2x text-white" />{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    <a href="/">
                      <i className="fa-brands fa-github  fa-2x text-white" />{" "}
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12"></div>
          </div>
          <div className="divider bg-dark p-0  border-top"> </div>
          <div className="row px-2 text-center py-3">
            <div className="col-lg-6 col-sm-12 text-lg-start ">
            <i className="fa-solid fa-copyright "></i> 2022{" "}
              <strong>
                <span className="font-weight-bold rounded-1  fw-bold">
                  IDEAL
                </span>{" "}
                TRAINING  SCIENCE ACADEMY 
              </strong>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="text-lg-end  ">
                Terms of Use | Privacy Policy | Cookie Policy
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
