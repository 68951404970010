import * as Yup from "yup";
import React, { useState } from "react";
import axios from "axios";
import { Form, Field, Formik, ErrorMessage } from "formik";
import {
  FormGroup,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { setauth } from "../Redux/Slice/auth";
import { useSelector } from "react-redux";
import { setUser } from "../Redux/Slice/user";
import { useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Fragment } from "react";
export default function AuthUser() {
  const navigate = useNavigate();
  const location = useLocation();
  // Login /Sigup Toggle
  const [loginToggle, setloginToggle] = useState(true);
  // Modal open state
  const [modal, setModal] = useState(false);
  // Modal open state
  const [errmessage, seterrmessage] = useState(null);
  // Toggle for Modal
  const toggle = () => setModal(!modal);
  //use Redux set Auth & User
  const dispatch = useDispatch();


  const handleSubmit = (values) => {
    let user = [];
    if (loginToggle) {
      user = { email: values.email, password: values.password };
      axios
        .post("/user/signin", user)
        .then((res) => {
          toggle();
          dispatch(setUser(res.data));
          dispatch(setauth({ islogin: true }));
          localStorage.setItem("token", res.data.token);
          navigate("/");
        })
        .catch((err) => {
          seterrmessage(err.response.data);
          console.log(err.response.data);
        });
    } else {
      user = {
        _id: values._id,
        email: values.email,
      };
      navigate("/");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(setauth({ islogin: false }));
    dispatch(
      setUser({
        _id: "",
        email: "",
      })
    );
    navigate("/");
  };
  let islogin = useSelector((state) => state.auth.islogin);
  let activeUser = useSelector((state) => state.user.email);

  useEffect(() => {
    seterrmessage(null);
  }, []);

  let initialvalues = {
    name: "",
    cellno: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };
  let validationSchema = loginToggle
    ? Yup.object().shape({
        email: Yup.string()
          .email("invalid email")
          .required("email is required"),
        password: Yup.string().required("password is required"),
      })
    : Yup.object().shape({
        cellno: Yup.string().required("cell no is required"),
        name: Yup.string().required("user name is required"),
        email: Yup.string()
          .email("invalid email")
          .required("email is Required"),
        password: Yup.string()
          .required("password is required")
          .min(6, "password must be at least 6 characters")
          .oneOf(
            [Yup.ref("passwordConfirmation"), null],
            "passwords must match"
          ),
        passwordConfirmation: Yup.string()
          .required("confirm password is required")
          .oneOf([Yup.ref("password"), null], "passwords must match"),
      });
  const forgetPassword=()=>{
        alert('kkk')
      }

  return (
    <>
      {
        <ul className="navbar-nav me-auto my-2 my-lg-0 align-items-center  text-white">
          {!islogin ? (
            <Fragment>
              <li
                className="nav-item p-lg-2"
                onClick={() => {
                  toggle();
                  setloginToggle(false);
                }}
              >
                Create Account?
              </li>
              <li
                className="nav-item btn btn-outline-light"
                onClick={() => {
                  toggle();
                  setloginToggle(true);
                  seterrmessage(null);
                }}
              >
                Login
              </li>
            </Fragment>
          ) : (
            <Fragment>
              <li className="nav-item text-white  p-lg-2">{activeUser}</li>
              <li className="btn btn-outline-light nav-item" onClick={logout}>
                {" "}
                Logout
              </li>
            </Fragment>
          )}
        </ul>
      }

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {loginToggle ? "Login" : "Sign Up"}
        </ModalHeader>
        <ModalBody>
          {loginToggle ? (<Fragment>
            <Formik
              initialValues={initialvalues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {() => (
                <Form className="form-container">
                  <span style={{ color: "red", padding: "0px", margin: "0px" }}>
                    {errmessage}
                  </span>
                  <FormGroup>
                    <Field
                      type="text"
                      name="email"
                      onFocus={() => seterrmessage(null)}
                      className={"form-control"}
                      placeholder="Email"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="password"
                      name="password"
                      className={"form-control"}
                      placeholder="Password"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="password"
                    />{" "}
                  </FormGroup>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <span
                    className=" text-primary text-decoration-underline px-2"
                    onClick={forgetPassword}
                  >
                    Forget Password?
                  </span>
                </Form>
              )}
            </Formik>
            
  
            
            </Fragment>
          ) : (
            <Formik
              initialValues={initialvalues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {() => (
                <Form className="form-container">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Field
                          type="text"
                          name="name"
                          className={"form-control"}
                          placeholder="Full Name"
                        />
                        <ErrorMessage
                          style={{ color: "red" }}
                          name="name"
                          component="div"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Field
                      type="text"
                      name="email"
                      className={"form-control"}
                      placeholder="Email"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="email"
                      component="div"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="text"
                      name="cellno"
                      className={"form-control"}
                      placeholder="Cell Number"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="cellno"
                      component="div"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="password"
                      name="password"
                      className={"form-control"}
                      placeholder="Password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="password"
                      name="passwordConfirmation"
                      className={"form-control"}
                      placeholder="Password Confirmation"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="password"
                      component="div"
                    />{" "}
                  </FormGroup>
                  <button type="submit" className="btn btn-primary">
                    Sign Up
                  </button>
                  <span
                    className=" text-primary text-decoration-underline px-2"
                    onClick={() => setloginToggle(true)}
                  >
                    Already User?
                  </span>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
        <ModalFooter>
          {loginToggle ? (
            <Button
              color="primary"
              onClick={() => {
                setloginToggle(false);
              }}
              outline
            >
              Sign Up
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}
