import React from "react";

const Layout = ({ Page, setPage, NoPage, PageHanding, children }) => {
  const Next = () => {
    if (Page < NoPage) {
      setPage(Page + 1);
    }
  };
  const Prev = () => {
    if (Page > 1) {
      setPage(Page - 1);
    }
  };

  const Header = () => {
    const percentage = Math.round((Page * 100) / NoPage);
    return (
      <div className="row d-flex  justify-content-center align-items-center">
        <ul className="nav nav-pills py-2" id="pills-tab" role="tablist">
          {PageHanding.map((PageHeaderItems, i) => {
            return (
              <li className="nav-item" role="presentation">
                <button
                  className={"nav-link" + (Page === i + 1 ? " active" : "")}
                  id={"pills-" + PageHeaderItems + "-tab"}
                  data-bs-toggle="pill"
                  data-bs-target={"#pills-" + PageHeaderItems}
                  type="button"
                  role="tab"
                  aria-controls={"pills-" + PageHeaderItems}
                  aria-selected={Page === i + 1}
                  onClick={() => setPage(i + 1)}
                >
                  {PageHeaderItems}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="progress  p-0">
          <div
            className={
              "progress-bar"+(Page===1?" bg-danger":(Page===2?" bg-info":" bg-success"))}
            role="progressbar"
            style={{ width: percentage + "%" }}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    );
  };
  const PrevNextRender = () => {
    return (
      <div className="btn-group row w-100 m-auto" role="group">
        <div className="col-3 d-flex align-items-center ">
          <button
            type="button"
            className={
              "btn mx-auto" + (Page === 1 ? " btn-danger" : " btn-primary")
            }
            onClick={Prev}
            disabled={Page === 1}
          >
            <i className="fa-solid fa-circle-arrow-left pe-sm-2"></i>{" "}
            <span className="d-none d-sm-inline">PREV</span>
          </button>
        </div>
        <div className="col-6"></div>
        <div className="col-3 d-flex align-items-center">
          <button
            type="button"
            className={
              "btn mx-auto" + (Page === NoPage ? " btn-danger" : " btn-success")
            }
            onClick={Next}
            disabled={Page === NoPage}
          >
            <span className="d-none d-sm-inline"> NEXT</span>
            <i className="fa-solid fa-circle-arrow-right ps-sm-2"></i>
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="container">
      <Header />
      <div className="tab-content">
        <div
          className={"tab-pane fade show active"}
          id={"pills-" + PageHanding[Page - 1]}
          role="tabpanel"
          aria-labelledby={"pills-" + PageHanding[Page - 1] + "-tab"}
        >
          {children}
        </div>
      </div>
      <PrevNextRender />
    </div>
  );
};

export default Layout;
