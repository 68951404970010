import React ,{Fragment}from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import img from "./../images/carousel_img/4.jpg";
import img2 from "./../images/carousel_img/6.jpg";
import img3 from "./../images/carousel_img/1.jpg";
import { ContactUs } from "../Component/ContactUs";
import { Link } from "react-router-dom";
import { AboutUs } from "../Component/AboutUs";
function Home() {
  document.title = "Home";
  return (
    <Fragment>
      <div className="p-0 m-0">
        <Carousel>
          <div>
            <img src={img} aria-hidden alt="" width="100%" height="100%" />
            <p className="legend">
              It is the supreme art of the teacher to awaken joy in creative
              expression and knowledge
            </p>
          </div>
          <div>
            <img src={img2} aria-hidden alt="" width="100%" height="100%" />
            <p className="legend">
              You have to grow from the inside out. None can teach you, none can
              make you spiritual. There is no other teacher but your own soul.
            </p>
          </div>
          <div>
            <img src={img3} aria-hidden alt="" width="100%" height="100%" />
            <p className="legend">
              The task of the modern educator is not to cut down jungles, but to
              irrigate deserts.
            </p>
          </div>
        </Carousel>
      </div>
      <div className="container p-0 m-0 pb-2">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
           <ContactUs/>
           <div className="d-flex align-items-center ">
           <i className="fa-solid fa-phone"></i>
             <strong className="ps-1 ">+92-306-7728788</strong>
             <Link name="" id="" className="btn btn-primary ms-auto d-inline-block" to="contact" role="button">Contact Us</Link>
           </div> 
          </div>
          <div className="col-lg-6 col-12">
          <AboutUs/>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { Home };
