import React from "react";
import Page from "../ui/Page";

const Teachers=()=>{

  return (
    <Page title="Welcome to Ideal Training Academy">
    
   
      
    </Page>
  );
}

export {Teachers};

