import { createSlice } from '@reduxjs/toolkit'
export const auth = createSlice({
  name: 'auth',
  initialState: {
    islogin:false
  },
  reducers: {
    setauth: (state, action) => {
      state.islogin = action.payload.islogin;
    },
   
  },
})

export const { setauth } = auth.actions
export default auth.reducer
