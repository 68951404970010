import React, { useEffect } from "react";
import { ToasterProvider } from "./ui/toasterCtx/ToasterContext";
import GlobalStyle from "./GlobalStyle";
import Layout from "./components/layout/Layout";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setauth } from "../Redux/Slice/auth";
import { setUser } from "../Redux/Slice/user";
import { useDispatch } from "react-redux";
import axios from "axios";

export default function index() {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.auth.islogin);
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .post("/verfytoken", { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.statusText === "OK") {
          axios
            .get("/getuserbyid/"+res.data.user_id)
            .then((res) => {
              dispatch(setUser(res.data));
              dispatch(setauth({ islogin: true }));
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
        dispatch(setauth({}));
        dispatch(setUser({}));
        navigate("/");
      });
  }, []);

  return islogin ? (
    <React.Fragment>
      <GlobalStyle />
      <ToasterProvider>
        <Layout>
          <Outlet />
        </Layout>
      </ToasterProvider>
    </React.Fragment>
  ) : (
    ""
  );
}
