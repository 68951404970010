import { Link } from 'react-router-dom';
import React from 'react'
const Error = () => {
  return (
    <div className='container text-center pt-5'>
      <h2>404</h2>
      <p>Page not found</p>
      <Link to='/'>Back to Home</Link>
    </div>
  );
};
export {Error};
