import React, { Component, Fragment } from 'react'
import NavBar from './NavBar';
export default class Header extends Component {
  render() {
    return (
      <div className=' container p-0 shadow-sm d-print-none'>
      <NavBar/>
      </div>
      
    )
  }
}
