import { createSlice } from '@reduxjs/toolkit'
export const user = createSlice({
  name: 'user',
  initialState: {
    email: "",
    _id: '',
    name:'',
    cellno:''
  },
  reducers: {
    setUser: (state, action) => {
      state.email = action.payload.email;
      state._id = action.payload._id;
      state.name = action.payload.name;
      state.cellno = action.payload.cellno;
    },

  },
})

export const { setUser } = user.actions
export default user.reducer
