import React, { Fragment } from 'react'

export const ContactUs = () => {
  return (
    <Fragment>
        
  
    <address className="px-lg-0 px-2">
      <p >
        <iframe
          title="This is a unique title 2"
          className="col-lg-12 col-md-12 col-sm-12 p-0 w-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.7282953570743!2d72.23897511458223!3d29.582507847201864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393c66abe032da15%3A0x8a19a558b256d0fc!2sStreet%20To%20People%20Bazaar%2C%20Khairpur%20Tamewali%2C%20Bahawalpur%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1648625609460!5m2!1sen!2s"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </p>
      <strong className="text-uppercase">
      <i className="fa-solid fa-location-dot"></i>{" "}
        IDEAL TRAINING SCIENCE ACADEMY
      </strong>{" "}
      <p className="ps-3 font-italic text-muted">
      SUPERIOR SCHOOL OF EXCELLENCE CHOWK SAUDIA, KHAIRPUR TAMEWALI(63060) , BAHAWALPUR,PUNJAB,PAKISTAN
      </p>
      <p >
      <i className="fa-solid fa-envelope"></i>
      <a  className=" ps-2 " href={`mailto:admin@idealtsa.com?subject=""&body=""`}>
      admin@idealtsa.com
    </a>
      </p>{" "}
 
    </address>

    </Fragment>
  )
}
