import styled from 'styled-components';
import React from 'react'



const StyledDiv = styled.div`
    margin: 30px 0 30px 0;
    height: 2px;
    background-color: #f0f0f0;
`;

export default function Divider() {
    return (
      
          <StyledDiv></StyledDiv>
     
    )
  }