import styled from "styled-components";
import NavLink from "./NavLink";
import {breakpoints as bp} from '../../../GlobalStyle';
import React from 'react';
const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${p => Number(!p.compact)};
  padding: 24px 0 14px 0;
  margin-right: 2px;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(var(--color-secondary-rgb), 0.1);
  transition: flex-grow 0.3s cubic-bezier(0.4, 0, 1, 1);
  ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  @media(max-width: ${bp.desktop}) {
      flex-grow: 1;
  }
`;

const DenseNavLinks = styled(NavLink)`
  && {
    box-shadow: none;
    min-height: 36px;
  }
`;

const links = [
  {
    to: "/",
    icon: "fa-solid fa-box",
    label: "Dashboard",
  },
  {
    to: "/teachers",
    icon: "fa-solid fa-person-chalkboard",
    label: "Teachers",
  },
  {
    to: "/students",
    icon: "fa-solid fa-children",
    label: "Students",
  },
  {
    to: "/course",
    icon: "fa-solid fa-graduation-cap",
    label: "Course",
  },
  {
    to: "/subject",
    icon: "fa-solid fa-book",
    label: "Subject",
  },
 
];

function NavLinksGroup(props) {
  return (
    <LinksGroup {...props}>
    <DenseNavLinks
          compact={props.compact}
          key="home"
          to='/'
          iconClassName="fa-solid fa-house-user"
          label="Home"
        />
      {links.map((l) => (
        <DenseNavLinks
          compact={props.compact}
          key={l.to}
          to={''.concat('/dashboard', l.to)}
          iconClassName={l.icon}
          label={l.label}
        />
      ))}
    </LinksGroup>
  );
}

export default NavLinksGroup;
