import React,{useEffect ,useState} from "react";
import { NavLink ,useNavigate} from "react-router-dom";
import img from "../../images/logo.png";
import { useSelector ,useDispatch} from "react-redux";
import AuthUser from "../../Component/AuthUser";
import { setauth } from "../../Redux/Slice/auth";
import { setUser } from "../../Redux/Slice/user";
import axios from "axios";
export default function NavBar() {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.auth.islogin);
  const dispatch = useDispatch();
  const NavBarList = [
    { To: "/", Content: "Home",visitor:true},
    { To: "/paper", Content: "Paper",visitor:islogin },
    { To: "/quiz", Content: "Quiz", visitor:islogin},
    { To: "/contact", Content: "Contact Us",visitor:true},
    { To: "/about", Content: "About Us",visitor:true},
  ];
  //const [checkValidation ,setValidation]=useState(true);
  useEffect(() => {
    axios
      .post("/user/verfytoken", { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.statusText === "OK") {
          axios
            .get("/user/getuserbyid/"+res.data.user_id)
            .then((res) => {
              dispatch(setUser(res.data));
              dispatch(setauth({ islogin: true }));
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
        dispatch(setauth({ islogin: false }));
        dispatch(setUser({}));
        navigate("/");
      }); 
      // const interval = setInterval(() => {
      //   setValidation(!checkValidation);
      // }, 1000);
      // return () => clearInterval(interval);
  }, []) /// trigger checkValidation
  return (
    <nav className="navbar container navbar-expand-lg navbar-dark bg-primary px-2">
      <div className="container-fluid px-0">
        <NavLink className="navbar-brand" to="/">
          <img
            src={img}
            className=" bg-white rounded-circle"
            height={50}
            alt="Ideal Training Science Academy"
            
          />
          <div className="ps-2 d-lg-inline-block text-uppercase d-none" height={28}>
          Ideal Training Science Academy
          </div>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul
            className="navbar-nav me-auto my-2 my-lg-0"
          >
            {NavBarList.map((list, i) => {
              return (
                list.visitor&&<li className="nav-item" key={i}>
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to={list.To}
                  >
                    {list.Content}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <form className="d-flex">
            <AuthUser/>
          </form>
        </div>
      </div>
    </nav>
  );
}
