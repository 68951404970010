import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Row, FormGroup } from "reactstrap";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Reaptcha from "reaptcha";
import axios from "axios";
import { ContactUs } from "../Component/ContactUs";

const SITE_KEY = "6Lc80qggAAAAAAhJ6pydj96aOSb81qfs9T1C3kKH";
// const ServiceID = "service_p11ylbi";
// const TemplateID = "template_2zxiipt";
// const PublicKey = "Dri9HYNPnRUNq-WI3";

const Contact = () => {
  document.title = "Contact Us";
  const [verified, setverified] = useState(false);
  const [status, setStatus] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("invalid email")
      .required("Email is required"),
    tel: Yup.string().required("Cell no is required"),
    fullName: Yup.string().required("Full name is required"),
    subject: Yup.string()
      .min(3, "Subject minimum length 3")
      .required("Subject is required"),
    message: Yup.string()
      .min(10, "Message minimum length 10")
      .required("Message is required"),
  });

  const onVerify = (recaptchaResponse) => {
    setverified(true);
  };
  const onExpire = () => {
    setverified(false);
    setStatus(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setverified(false);
      setStatus(false);
    }, 3000);
  }, [status]);

  const handleSubmit = (e) => {
    if (verified) {
      setStatus(true);
      toast.info("Messag is Sending", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      axios
        .post("/mailer", e)
        .then((res) => {
          if (res.statusText === "OK") {
            toast.success("Successfully Send Message", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setStatus(false);
          } else {
            console.log(res);
            toast.error("Something went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error("Something went Wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      toast.error("Are you are human being?", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="container p-0 py-5">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row className="m-0 ">
        {!status ? (
          <Col md={6} sm={12}>
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                tel: "",
                subject: "",
                message: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {(values) => (
                <Form className="form-container">
                  <FormGroup>
                    <h3 className=" text-uppercase">Contact Us</h3>
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="text"
                      name="fullName"
                      className={"form-control"}
                      placeholder="Full Name"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="fullName"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="text"
                      name="email"
                      className={"form-control"}
                      placeholder="Email"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="tel"
                      name="tel"
                      className={"form-control"}
                      placeholder="Cell No."
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="tel"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="text"
                      name="subject"
                      className={"form-control"}
                      placeholder="Subject"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="subject"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="text"
                      as="textarea"
                      name="message"
                      className={"form-control"}
                      placeholder="Message"
                      rows="3"
                    />
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="message"
                    />
                  </FormGroup>

                  <FormGroup className="d-flex justify-content-end ">
                    <Reaptcha
                      sitekey={SITE_KEY}
                      onVerify={onVerify}
                      onExpire={onExpire}
                    />
                  </FormGroup>

                  <FormGroup className="d-flex justify-content-end">
                    <button
                      className={"btn rounded p-2 px-5 fw-bold btn-primary "}
                      type="submit"
                      disabled={status}
                    >
                      Send
                    </button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Col>
        ) : (
          <Col md={6} sm={12}>
            Sending message........
          </Col>
        )}

        <Col md={6} sm={12}>
          <ContactUs />
          <div className="d-flex align-items-center ">
            <i className="fa-solid fa-phone"></i>
            <strong className="ps-1 ">+92-306-7728788</strong>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export { Contact };
