import { configureStore } from '@reduxjs/toolkit'
import user from './Slice/user';
import { setupListeners } from '@reduxjs/toolkit/query';
import { UsersApi } from './api/UsersApi';
import auth  from './Slice/auth';
import SelectTopic from './Slice/SelectTopic';
import Step  from './Slice/Step';
import CounterDown  from './Slice/CounterDown';
import  QuestionHandle  from './Slice/QuestionHandle';
import AnswerHandle  from './Slice/AnswerHandle';
const store = configureStore({
    reducer: {
        user,
        auth,
        SelectTopic,
        Step,
        CounterDown,
        QuestionHandle,
        AnswerHandle,
       [UsersApi.reducerPath]:UsersApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>getDefaultMiddleware().concat(UsersApi.middleware),



    devTools: true,
})


setupListeners(store.dispatch);
export default store;