import React from "react";
import Page from "../ui/Page";

const Students=()=>{

  return (
    <Page title="Welcome to Ideal Training Academy">
    
   Students
      
    </Page>
  );
}

export {Students};

