import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Home, About, Contact, Error } from "./Pages/index";
import { Layout } from "./Pages/Layout/index";
import Dashboard from "./dashboard";
import {
  Default,
  Teachers,
  Students,
  Subject,
  Course,
} from "./dashboard/pages/index";
import { Quiz } from "./Quiz/index";
import { Paper } from "./Paper/index";

export default function App() {
  let token = false;
  useEffect(() => {
    token = localStorage.getItem("token") === null;
  }, []);


  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route 
             path="quiz" 
             element={token ? <Navigate to="/" /> : <Quiz />} />
          <Route
            path="paper"
            element={token ? <Navigate to="/" /> : <Paper />}
          />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
        </Route>

        <Route path="*" element={<Error />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Default />} />
          <Route path="teachers" element={<Teachers />} />
          <Route path="students" element={<Students />} />
          <Route path="Course" element={<Course />} />
          <Route path="Subject" element={<Subject />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
