import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import React from 'react'
import { AboutUs } from './../Component/AboutUs';

 function About() {
  return (
    <div>
    <Helmet>
    <title>About Us</title>
    <meta name="description" content="About Us" />
    <meta name="keywords" cpntent="About Us,Address" />
   </Helmet>

    <div className="bg-light">
      <div className="container py-5">
        <div className="row h-100 align-items-center py-5">
          <div className="col-lg-6">
          <h2 className="px-lg-0 px-2 font-weight-light">Our Vision</h2>
          <p className="px-lg-0 px-2 font-italic text-muted">
            Our vision is to help you by providing internationally recognised
            professional qualifications, aligned with business and soft skills
            training, to educate future leaders; those you need to succeed in
            the global marketplace.
          </p>
          </div>
          <div className="col-lg-6 d-none d-lg-block"><img src="https://bootstrapious.com/i/snippets/sn-about/illus.png" alt="" className="img-fluid" /></div>
        </div>
      </div>
    </div>
    <div className="bg-white py-5">
      <div className="container py-5">
        <div className="row align-items-center mb-5">
          <div className="col-lg-6 order-2 order-lg-1"><i className="fa fa-bar-chart fa-2x mb-3 text-primary" />
            <h2 className="font-weight-light">Our Aims</h2>
           <ul className="px-3 ms-2 font-italic text-muted">
      <li style={{ listStyleType: "disc" }}>
        Promoting academic excellence
      </li>
      <li style={{ listStyleType: "disc" }}>
        Enhancing analytical and study skills
      </li>
      <li style={{ listStyleType: "disc" }}>
        Building leadership skills
      </li>
      <li style={{ listStyleType: "disc" }}>
        Fostering an ethical outlook and sense of civic responsibility
      </li>
      <li style={{ listStyleType: "disc" }}>
        developing an understanding of global issues
      </li>
      <li style={{ listStyleType: "disc" }}>Encouraging creativity.</li>
    </ul>
            </div>
          <div className="col-lg-5 px-5 mx-auto order-1 order-lg-2"><img src="https://bootstrapious.com/i/snippets/sn-about/img-1.jpg" alt="" className="img-fluid mb-4 mb-lg-0" /></div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 px-5 mx-auto"><img src="https://bootstrapious.com/i/snippets/sn-about/img-2.jpg" alt="" className="img-fluid mb-4 mb-lg-0" /></div>
          <div className="col-lg-6"><i className="fa fa-leaf fa-2x mb-3 text-primary" />
            <h2 className="font-weight-light">Lorem ipsum dolor sit amet</h2>
            <p className="font-italic text-muted mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><NavLink to="#" className="btn btn-light px-5 rounded-pill shadow-sm">Learn More</NavLink>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-light py-5">
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-lg-5">
            <h2 className="display-4 font-weight-light">Our team</h2>
            <p className="font-italic text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          </div>
        </div>
        <div className="row text-center">
          {/* Team item*/}
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://bootstrapious.com/i/snippets/sn-about/avatar-2.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
              <h5 className="mb-0">Muhammad Majid</h5><span className="small text-uppercase text-muted">CEO - Founder</span>
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-facebook-f" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-twitter" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-instagram" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-linkedin" /></NavLink></li>
              </ul>
            </div>
          </div>
          {/* End*/}
          {/* Team item*/}
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://bootstrapious.com/i/snippets/sn-about/avatar-3.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
              <h5 className="mb-0">Aman ul Allah</h5><span className="small text-uppercase text-muted">CEO - Founder</span>
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-facebook-f" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-twitter" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-instagram" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-linkedin" /></NavLink></li>
              </ul>
            </div>
          </div>
          {/* End*/}
          {/* Team item*/}
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://bootstrapious.com/i/snippets/sn-about/avatar-2.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
              <h5 className="mb-0">Ejaz Ahmad</h5><span className="small text-uppercase text-muted">CEO - Founder</span>
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-facebook-f" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-twitter" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-instagram" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-linkedin" /></NavLink></li>
              </ul>
            </div>
          </div>
          {/* End*/}
          {/* Team item*/}
          <div className="col-xl-3 col-sm-6 mb-5">
            <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://bootstrapious.com/i/snippets/sn-about/avatar-1.png" alt="" width={100} className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
              <h5 className="mb-0">Muhammad Rashid</h5><span className="small text-uppercase text-muted">CEO - Founder</span>
              <ul className="social mb-0 list-inline mt-3">
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-facebook-f" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-twitter" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-instagram" /></NavLink></li>
                <li className="list-inline-item"><NavLink to="#" className="social-link"><i className="fa fa-linkedin" /></NavLink></li>
              </ul>
            </div>
          </div>
          {/* End*/}
        </div>
      </div>
    </div>
  </div>
  )
}

export {About}