import React from "react";
import SelectTopic  from "../components/SelectTopic";
import Page from "../ui/Page";

const Course = () => {

  return (
    <Page title="Welcome to Ideal Training Academy">
      <SelectTopic/>
    
 
    </Page>
  );
};

export { Course };
