import React, { Fragment } from 'react'

export const AboutUs = () => {
  return (
    <Fragment>
    
    <h2 className="px-lg-0 px-2 font-weight-light">About Us</h2>
    <p className="px-lg-0 px-2 font-italic text-muted">
      Our vision is to help you by providing internationally recognised
      professional qualifications, aligned with business and soft skills
      training, to educate future leaders; those you need to succeed in
      the global marketplace.
    </p>
    <h6 className="px-lg-0 px-2 font-weight-light">Our aims for our students include:</h6>
    <ul className="px-3 ms-2 font-italic text-muted">
      <li style={{ listStyleType: "disc" }}>
        Promoting academic excellence
      </li>
      <li style={{ listStyleType: "disc" }}>
        Enhancing analytical and study skills
      </li>
      <li style={{ listStyleType: "disc" }}>
        Building leadership skills
      </li>
      <li style={{ listStyleType: "disc" }}>
        Fostering an ethical outlook and sense of civic responsibility
      </li>
      <li style={{ listStyleType: "disc" }}>
        developing an understanding of global issues
      </li>
      <li style={{ listStyleType: "disc" }}>Encouraging creativity.</li>
    </ul>
   
  
    </Fragment>
  )
}
