import styled from "styled-components";
import React from 'react';
import { breakpoints as bp } from "../../GlobalStyle";
//import { useAuth } from "../../authContext";
import Tooltip from "../../ui/Tooltip";
import { Avatar, IconButton ,Button} from "../../ui/core";
import AccountInfo from "./header/AccountInfo";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { setauth } from './../../../Redux/Slice/auth';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  height: 48px;
  align-items: stretch;
  padding: 0 24px;
  > div {
    display: flex;
    align-items: center;
  }
  button {
    white-space: nowrap;
  }
  .nav-toggle {
    pointer-events: none;
    opacity: 0;
    @media (max-width: ${bp.desktop}) {
      opacity: 1;
      pointer-events: all;
    }
  }
`;


function Header({ toggle }) {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const user = useSelector((state) => state.user);
  const auth = {'user':{'profileImage':'#','displayName':user.name ,'email':user.email}}
  return (
    <Grid>
      <IconButton
        className="nav-toggle"
        onClick={toggle}
        icon={faBars}
        style={{ marginLeft: "-11px" }}
      />

      <div className="mid"></div>
      <div>
        <Tooltip text={<AccountInfo user={auth.user} />}>
          <IconButton bg="primary" size={5}>
            <Avatar
              size={5}
              bg="primary"
              image='/logo192.png'
              name={auth.user.displayName || auth.user.email}
            ></Avatar>
          </IconButton>
        </Tooltip>
       <Tooltip
          text="Click for LogOut"
          bg="secondary"
        >
          <Button onClick={() => {localStorage.removeItem('token'); navigate('/'); dispatch(setauth({ 'islogin': false }));}}>Sign Out</Button>
        </Tooltip>
      </div>
    </Grid>
  );
}

export default Header;
